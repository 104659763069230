import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [{
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'index',
        },
    },
    {
        name: 'user',
        path: '/user',
        component: () =>
            import ('./view/user'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'not',
        path: '/not',
        component: () =>
            import ('./view/index/not.vue'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'hunt',
        path: '/hunt',
        component: () =>
            import ('./view/hunt'),
        meta: {
            // title: '寻宝',
        },
    },
    {
        name: 'pass',
        path: '/pass',
        component: () =>
            import ('./view/user/pass'),
        meta: {},
    },
    {
        name: 'address',
        path: '/address',
        component: () =>
            import ('./view/user/address'),
        meta: {},
    },
    {
        name: 'rec',
        path: '/rec',
        component: () =>
            import ('./view/user/rec'),
        meta: {},
    },
    {
        name: 'apply',
        path: '/apply',
        component: () =>
            import ('./view/user/apply'),
        meta: {},
    },
    {
        name: 'reward',
        path: '/reward',
        component: () =>
            import ('./view/user/reward'),
        meta: {},
    },
    {
        name: 'point',
        path: '/point',
        component: () =>
            import ('./view/user/point'),
        meta: {},
    },
    {
        name: 'well',
        path: '/well',
        component: () =>
            import ('./view/user/well'),
        meta: {},
    },
    {
        name: 'qb',
        path: '/qb',
        component: () =>
            import ('./view/user/qb'),
        meta: {},
    },
    {
        name: 'mail',
        path: '/mail',
        component: () =>
            import ('./view/mail'),
        meta: {},
    },
    {
        name: 'mail_l1',
        path: '/mail_l1',
        component: () =>
            import ('./view/mail/list1.vue'),
        meta: {},
    },
    {
        name: 'mail_l2',
        path: '/mail_l2',
        component: () =>
            import ('./view/mail/list2.vue'),
        meta: {},
    },
    {
        name: 'wallet',
        path: '/wallet',
        component: () =>
            import ('./view/wallet'),
        meta: {},
    },
    {
        name: 'air',
        path: '/air',
        component: () =>
            import ('./view/wallet/air.vue'),
        meta: {},
    },
    {
        name: 'otc',
        path: '/otc',
        component: () =>
            import ('./view/otc'),
        meta: {},
    },
    {
        name: 'goods',
        path: '/goods',
        component: () =>
            import ('./view/user/goods'),
        meta: {},
    },
    {
        name: 'shop',
        path: '/shop',
        component: () =>
            import ('./view/user/shop'),
        meta: {},
    },
    {
        name: 'order',
        path: '/order',
        component: () =>
            import ('./view/order'),
        meta: {},
    },
    {
        name: 'team',
        path: '/team',
        component: () =>
            import ('./view/user/team'),
        meta: {},
    },
    {
        name: 'invite',
        path: '/invite',
        component: () =>
            import ('./view/user/invite'),
        meta: {},
    },
    {
        name: 'exchange',
        path: '/exchange',
        component: () =>
            import ('./view/exchange'),
        meta: {},
    },
   
  
    {
        name: 'bank',
        path: '/bank',
        component: () =>
            import ('./view/user/bank'),
        meta: {},
    },
    {
        name: 'info',
        path: '/info',
        component: () =>
            import ('./view/user/info'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'ship',
        path: '/ship',
        component: () =>
            import ('./view/ship'),
        meta: {
            // title: '购物车',
        },
    },
    {
        name: 'hoc',
        path: '/hoc',
        component: () =>
            import ('./view/hoc'),
        meta: {
            // title: '购物车',
        },
    },
    {
        name: 'destor',
        path: '/destor',
        component: () =>
            import ('./view/hoc/xiao.vue'),
        meta: {
            // title: '购物车',
        },
    },

    {
        name: 'detail',
        path: '/datail',
        component: () =>
            import ('./view/dao/detail'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'ac_detail',
        path: '/ac_datail',
        component: () =>
            import ('./view/news/detail'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'ac_list',
        path: '/ac_list',
        component: () =>
            import ('./view/news/list'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'shop',
        path: '/shop',
        component: () =>
            import ('./view/shop'),
        meta: {
            // title: '船厂',
            refresh: true
        },
    },
    {
        name: 'nft',
        path: '/nft',
        component: () =>
            import ('./view/shop/my'),
        meta: {
            // title: '船厂',
            refresh: true
        },
    },
    {
        name: 'login',
        path: '/login',
        component: () =>
            import ('./view/login'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'reg',
        path: '/reg',
        component: () =>
            import ('./view/login/reg'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'search',
        path: '/search',
        component: () =>
            import ('./view/search'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'index',
        path: '/index',
        component: () =>
            import ('./view/index'),
        meta: {
            // title: '商城首页',
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export { router };