//获取随机数
export const getsuiji = function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//px获取VW值

export const getvw = function convertPxToVw(pxValue) {
    // 使用 PostCSS 来转换像素值到 vw 单位  
    let vwValue = pxValue * (100 / window.innerWidth); // 这是基本的转换逻辑，你可能需要调整以适应你的需求  
    return vwValue;
}



export const setRem = function setRem() {
    // 1920 默认大小16px; 1920px = 120rem ;每个元素px基础上/16
    // const screenWidth = 1920
    // const scale = screenWidth / 16
    // const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    // // 得到html的Dom元素
    // const htmlDom = document.getElementsByTagName('html')[0]
    // // 设置根元素字体大小
    // htmlDom.style.fontSize = htmlWidth / scale + 'px'

    (function(doc, win) {

        var docEl = doc.documentElement,
            resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
            recalc = function() {
                var clientWidth = docEl.clientWidth;
                if (!clientWidth) return;
                if (clientWidth >= 860) {
                    docEl.style.fontSize = '100px';
                } else {
                    docEl.style.fontSize = 100 * (clientWidth / 860) + 'px';
                }
            };

        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener('DOMContentLoaded', recalc, false);

    })(document, window)
}