import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import Lazyload from "vue-lazyload";
import { reactive } from 'vue'; 
import VueClipboard2 from 'vue-clipboard2';

window.globalState = reactive({  
    head_bg: ''  
  });

const app = createApp(App);
app.use(router);
app.use(VueClipboard2)
app.use(Lazyload, {
    loading: "@/assets/img/image.png", //可以指定加载中的图像
    error: "@/assets/img/image.png", //可以指定加载失败的图像
});
app.mount('#app');